/* eslint-disable react-hooks/exhaustive-deps */
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import "App.css";
import RouterList from "RouterList";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { UserProfileContext } from "context-api/UserProfile";
import { AtarevProgressLinearContext } from "context-api/AtarevProgressLinear";
import { UserClient } from "lib/api/user/User";
import { IRoute } from "types/route";
import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import RulesPriority from "components/lfa/RulesComponents/RulesPriority";
import ProgressLinear from "components/ProgressLinear";
import LandingPage from "./components/LandingPage/LandingPage";
import LOGIN from "modules/login";
import HOME from "modules/home";
import TestPage from "modules/testPage/index-table-example";
import AdminPage from "modules/testPage/index-filter-scraper-config";
import DASHBOARD from "modules/dashboard";
import { UserActivityClient } from "lib/api/user/UserActivity";

export default function App() {
  const defaultRoute = {
    icon: "",
    name: "",
    path: "",
    disabled: false,
    component: () => <span></span>,
  };
  const [cookies, setCookie] = useCookies(["token"]);
  const [routes, setRoutes] = useState<IRoute[]>([defaultRoute]);
  const [pathname, setPathname] = useState(window.location.pathname);
  const [oldPathname, setOldPathname] = useState(window.location.pathname);
  const [currentModule, setCurrentModule] = useState<string | null>(null);

  const startTime: any = useRef(new Date()); // Başlangıç zamanını saklamak için

  const { theme, setTheme } = useContext(ThemeContext);
  const { isProgress, setIsProgress } = useContext(AtarevProgressLinearContext);
  const { filterList, setFilterList } = useContext(FilterContext);
  const { user, setUser, setUserLoader } = useContext(UserProfileContext);
  const location = window.location;

  useEffect(() => {
    setIsProgress(true);
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.className = theme;
      setTheme(theme);
    } else {
      localStorage.setItem("theme", "dark");
      document.body.className = "dark";
      setTheme("dark");
    }
    if (cookies.token && user === null) {
      setUserLoader(true);
      UserClient.fetchUser({ token: cookies.token })
        .then((res: any) => {
          const defaultSelectFilter = {
            ...filterList,
            ...res.defaultFilterSelection?.global,
          };
          setUser(res ?? null);

          localStorage.setItem(
            "user",
            JSON.stringify({
              ...res,
              defaultFilterSelection: defaultSelectFilter,
            })
          );

          setFilterList(defaultSelectFilter);

          // Backend'den gelen rotaların tutulacağı değişken
          let backendRoute: any = [];

          if (res?.enabledModules) {
            Object.keys(res?.enabledModules).forEach((module) => {
              //Eğer module etkin ise erişilebilecek tüm sayfaları alıyoruz.
              if (res?.enabledModules[module]?.enabled) {
                backendRoute.push(...res?.enabledModules[module]?.pages);
              }
            });

            // Ön uçtan comparative-analysis sayfasına erişimi kapatıyoruz.
            backendRoute = backendRoute.filter(
              (el) => el !== "/comparative-analysis"
            );

            // Rotalar backend'den geliyorsa erişime açıyoruz
            let newRoutes: IRoute[] = [];
            Object.keys(RouterList).forEach((module: string) => {
              RouterList[module].forEach((el: IRoute) => {
                newRoutes.push({
                  ...el,
                  disabled: !backendRoute.includes(el.path),
                });

                // Modulleri sistem içerisindeki dropdown kullanımları için tarayıcıya kaydediyoruz.
                if (window.location.pathname === el.path) {
                  localStorage.setItem("module", module);
                }
              });
            });
            setRoutes(newRoutes);
          } else setRoutes([defaultRoute]);
        })
        .catch(() => {
          localStorage.removeItem("user"); // remove user from local storage
          setCookie("token", "");
        })
        .finally(() => {
          setIsProgress(false);
          setTimeout(() => {
            setUserLoader(false);
          }, 2000);
        });
    } else setIsProgress(false);
  }, [pathname]);

  const getCurrentModule = () => {
    for (const [module, routes] of Object.entries(RouterList)) {
      if (routes.some((route) => route.path === pathname)) {
        return module; // Modül ismini döndür
      }
    }
    return null; // Modül bulunamazsa null döndür
  };

  useEffect(() => {
    // Sayfa değiştiğinde süreyi hesapla
    const calculateTimeSpent = () => {
      const endTime: any = new Date();
      const timeSpent = Math.round((endTime - startTime.current) / 1000); // Süreyi saniye cinsinden hesapla

      // Log oluştur
      const logs = {
        module: currentModule,
        page: oldPathname.slice(1),
        seconds: timeSpent,
      };

      // Sayfa ilk kez yüklenmiş ise hesaplanmış süre 0 olacaktır.
      if (timeSpent > 0) {
        UserActivityClient.fetchUserActivity(logs)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // Yeni sayfa için başlangıç zamanını güncelle
      startTime.current = new Date();

      // Yeni sayfa için sayfa adını güncelle
      setOldPathname(pathname);

      // Yeni sayfa için module adını güncelle
      setCurrentModule(getCurrentModule());
    };

    // Sayfa değişikliğinde süreyi hesapla
    calculateTimeSpent();

    // Bileşen unmount olduğunda süreyi hesapla (sayfa tamamen terk edilirse)
    const handleBeforeUnload = () => calculateTimeSpent();
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [pathname]);

  return (
    <>
      {isProgress ? (
        <ProgressLinear />
      ) : (
        <Router>
          <div
            className="atarev_container"
            data-active={isProgress}
            data-theme={theme}
          >
            <Switch>
              <Route exact path="/">
                <ProtectedRoute
                  jwt={cookies.token}
                  component={LandingPage}
                  setPathname={setPathname}
                />
              </Route>
              <Route path="/login">
                <LOGIN />
              </Route>
              <Route path="/home">
                <ProtectedRoute
                  jwt={cookies.token}
                  component={HOME}
                  setPathname={setPathname}
                />
              </Route>
              <Route path="/rules-priority">
                <ProtectedRoute
                  jwt={cookies.token}
                  component={RulesPriority}
                  setPathname={setPathname}
                />
              </Route>
              {routes?.map(
                (route: IRoute) =>
                  !route.disabled && (
                    <Route path={route.path} key={route.path}>
                      <ProtectedRoute
                        jwt={cookies.token}
                        component={route.component}
                        setPathname={setPathname}
                      />
                    </Route>
                  )
              )}
              {(location.hostname.includes("localhost") ||
                location.hostname.includes("msd-dev")) && (
                <>
                  <Route path="/test-page">
                    <ProtectedRoute
                      jwt={cookies.token}
                      component={TestPage}
                      setPathname={setPathname}
                    />
                  </Route>
                  <Route path="/c-level">
                    <ProtectedRoute
                      jwt={cookies.token}
                      component={DASHBOARD}
                      setPathname={setPathname}
                    />
                  </Route>
                  <Route path="/admin-page">
                    <ProtectedRoute
                      jwt={cookies.token}
                      component={AdminPage}
                      setPathname={setPathname}
                    />
                    <AdminPage />
                  </Route>
                </>
              )}
              <Route exact path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </div>
        </Router>
      )}
    </>
  );
}
