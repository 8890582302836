/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import Plotly from "components/common/Charts/Plotly";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import { AvaibilityTrendsFilterContext } from "context-api/AvaibilityTrendsFilterContext";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { FareTrendsClient } from "lib/api/msd/fareTrends/FareTrends";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./styles.module.css";
import Dropdown from "components/common/Dropdown/Dropdown";
import { FlightsClient } from "lib/api/lfa/flights/Flights";
import StatisticsComponent from "../Statistics";
import StatisticsComponentHost from "../StatisticsHost";
import moment from "moment";
import { useQuery } from "hooks/useQuery";
import { FlightTypeSwitchContext } from "context-api/OwRtContext";
import { FareTrendsTableClient } from "lib/api/lfa/fareTrendsTable/FareTrendsTable";
import { Tooltip } from "components/common/Tooltip";
import { BasicFilterContext } from "context-api/BasicFilter";
import BasicFilter from "../BasicFilter";

interface IAvaibilityTrends {
  filterIsOpen: boolean;
  isOpen: boolean;
  stats: never[];
  setStats: Dispatch<SetStateAction<never[]>>;
  setData: Dispatch<
    SetStateAction<{
      data: any[];
      columns: any[];
    }>
  >;
  setLoading: Dispatch<SetStateAction<boolean>>;
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
  setMeta: Dispatch<
    SetStateAction<{
      currentPage: number;
      itemsPerPage: number;
      totalItems: number;
      totalPages: number;
    }>
  >;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  selectedCarriers: string[];
  setSelectedCarriers: Dispatch<SetStateAction<string[]>>;
  selectedFlightNumbers: string[];
  setSelectedFlightNumbers: Dispatch<SetStateAction<string[]>>;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  sortModel: {
    sortKey: string;
    sortOrder: "asc" | "desc";
  }[];
}

export default function AvaibilityTrends({
  filterIsOpen,
  isOpen,
  stats,
  setStats,
  setData,
  setLoading,
  setMeta,
  activeStep,
  setActiveStep,
  selectedCarriers,
  setSelectedCarriers,
  selectedFlightNumbers,
  setSelectedFlightNumbers,
  paginationModel,
  sortModel,
}: IAvaibilityTrends) {
  const {
    avaibilityTrendsFilterList,
    setAvaibilityTrendsFilterList,
    setMaxDate,
  } = useContext(AvaibilityTrendsFilterContext);
  const { theme } = useContext(ThemeContext);
  const { filterList } = useContext(FilterContext);
  const { basicFilter } = useContext(BasicFilterContext);

  const [graphData, setGraphData] = useState([]);
  const [layout, setLayout] = useState({});
  const [carriersJson, setCarriersJson] = useState<any>();
  const [flightNumberOptions, setFlightNumberOptions] = useState<string[]>([]);
  const [flightNumberLookup, setFlightNumberLookup] = useState("");
  const { flightTypeSwitch, allFlightType, owRtSwitch, setOwRtSwitch } =
    useContext(FlightTypeSwitchContext);
  const [dataHost, setDataHost] = useState<{
    data: any;
    columns: any[];
  }>({
    data: null,
    columns: [],
  });

  let query = useQuery();

  useEffect(() => {
    const queryDate = query.get("date");
    const queryCabin = query.get("cabin");
    if (queryDate && queryCabin) {
      setAvaibilityTrendsFilterList((prevState) => ({
        ...prevState,
        date_range_start: queryDate,
      }));
      if (queryCabin === "Economy") {
        setActiveStep(169);
      }
      if (queryCabin === "Business") {
        setActiveStep(85);
      }
      if (queryCabin === "FIRST") {
        setActiveStep(0);
      }
    }
  }, []);

  useEffect(() => {
    if (!filterIsOpen && !isOpen && owRtSwitch) {
      const data = {
        ...avaibilityTrendsFilterList,
        orig_city_airport: filterList.origCityAirport,
        dest_city_airport: filterList.destCityAirport,
        cabin: activeStep === 169 ? "ECO" : activeStep === 85 ? "BUS" : "FIRST",
        graph_carriers: selectedCarriers?.join(),
        flight: selectedFlightNumbers,
        dark_theme: theme === "dark",
        currency: filterList.currency,
        type: owRtSwitch.toUpperCase(),
        fare_family: basicFilter,
      };
      FareTrendsClient.fetchFareTrends({
        data,
      })
        .then((res: any) => {
          setMaxDate(
            moment(res?.latest_date, "DD-MM-YYYY").isValid()
              ? new Date(moment(res?.latest_date, "DD-MM-YYYY").format())
              : null
          );
          setCarriersJson(res?.carriers);
          setGraphData(res.fig.data);
          setLayout(res.fig.layout);
          setStats(res?.stats?.data ?? []);
          setDataHost({
            data: res?.host_stats?.data ?? null,
            columns: getColumns(res?.host_stats?.labels),
          });
        })
        .catch((err) => err);
    }
  }, [
    avaibilityTrendsFilterList,
    basicFilter,
    filterList.origCityAirport,
    filterList.destCityAirport,
    filterList.currency,
    owRtSwitch,
    theme,
    activeStep,
    selectedCarriers,
    selectedFlightNumbers,
  ]);

  const getColor = (value) => {
    if (value && typeof value === "number") {
      if (value < 50) {
        return "#ec4252"; //Kırmızı
      } else if (value >= 50) {
        return "#00ad92"; //Yeşil
      }
    }
    return "#9c00ed";
  };

  useEffect(() => {
    if (!filterIsOpen && !isOpen && owRtSwitch) {
      setLoading(true);
      const params = {
        ...avaibilityTrendsFilterList,
        orig_city_airport: filterList.origCityAirport,
        dest_city_airport: filterList.destCityAirport,
        cabin: activeStep === 169 ? "ECO" : activeStep === 85 ? "BUS" : "FIRST",
        graph_carriers: selectedCarriers?.join(),
        flight: selectedFlightNumbers,
        type: owRtSwitch.toUpperCase(),
        dark_theme: theme === "dark",
        currency: filterList.currency,
        fare_family: basicFilter,
        page_: paginationModel.page,
        limit_: paginationModel.pageSize,
        ...(sortModel &&
          sortModel?.length > 0 && { sort_: JSON.stringify(sortModel) }), // _sort varsa ekle
      };
      FareTrendsTableClient.fetchFareTable(params)
        .then((res: any) => {
          setData({
            data: res?.data ?? [],
            columns: getColumns(res?.labels),
          });
          setMeta(res?.meta);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [
    avaibilityTrendsFilterList,
    basicFilter,
    filterList.origCityAirport,
    filterList.destCityAirport,
    filterList.currency,
    owRtSwitch,
    theme,
    activeStep,
    selectedCarriers,
    selectedFlightNumbers,
    paginationModel,
    sortModel,
  ]);

  const getColumns = (labels) => {
    const array: {
      title: string;
      key: string;
      cell: (row: any) => JSX.Element;
      width?: string | number;
      sortable?: boolean;
    }[] = [];
    for (const [key, title] of Object.entries({
      carrier: "CARRIER",
      ...labels,
    })) {
      if (key !== "carrier" && key !== "maf" && key !== "lf") {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return <span className={"rdb-custom-cell"}>{row[key]}</span>;
          },
          width: 200,
          sortable: true,
        };
        array.push(obj);
      } else if (key === "maf") {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return (
              <span className={"rdb-custom-cell"}>
                {`${row[key]?.currency} ${row[key]?.value}`}
              </span>
            );
          },
          width: 200,
          sortable: true,
        };
        array.push(obj);
      } else if (key === "lf") {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return (
              <Tooltip title={row?.LFLastupdatedAt ?? "-"}>
                <span
                  className={"rdb-custom-cell"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 8,
                  }}
                >
                  {row[key] && (
                    <span style={{ width: 30 }}>{`%${row[key] ?? "-"}`}</span>
                  )}
                  <span className={styles.bar_wrapper}>
                    <span
                      className={styles.bar}
                      style={{
                        backgroundColor: getColor(row[key]),
                        width:
                          row[key] &&
                          typeof row[key] === "number" &&
                          row[key] * 0.6,
                      }}
                    />
                  </span>
                </span>
              </Tooltip>
            );
          },
          sortable: true,
        };
        array.push(obj);
      } else {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return (
              <div
                className={styles.custom_table_row}
                style={{
                  backgroundColor: row[key]?.color || "rgba(255,0,0,0.2)",
                }}
              >
                <span className={"rdb-custom-cell"}>{row[key]?.value}</span>
              </div>
            );
          },
          width: 200,
          sortable: true,
        };
        array.push(obj);
      }
    }
    return array;
  };

  useEffect(() => {
    if (!filterIsOpen && !isOpen && owRtSwitch) {
      const flightParams = {
        ...avaibilityTrendsFilterList,
        orig_city_airport: filterList.origCityAirport,
        dest_city_airport: filterList.destCityAirport,
        cabin: activeStep === 169 ? "ECO" : activeStep === 85 ? "BUS" : "FIRST",
        lookup: flightNumberLookup,
        selected: selectedFlightNumbers,
        type: owRtSwitch.toUpperCase(),
        graph_carriers: selectedCarriers?.join(),
        fare_family: basicFilter,
      };
      FlightsClient.fetchFlights(flightParams)
        .then((res: any) => {
          setFlightNumberOptions(res?.flights ?? []);
        })
        .catch((err) => console.log(err));
    }
  }, [
    owRtSwitch,
    flightNumberLookup,
    avaibilityTrendsFilterList,
    basicFilter,
    filterList.origCityAirport,
    filterList.destCityAirport,
    isOpen,
    filterIsOpen,
    selectedFlightNumbers,
    selectedCarriers,
    activeStep,
  ]);

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList.mainCompetitor, filterList.selectedCompetitors, filterIsOpen]);

  return (
    <>
      <Card variant="secondary">
        <div className={styles.card_header}>
          <span className={styles.card_title} data-theme={theme}>
            Availability Trends
          </span>
          <div className={styles.card_header_buttons}>
            <div className={styles.rt_ow_switch_wrapper}>
              <span
                data-active={owRtSwitch === "rt"}
                onClick={() => {
                  flightTypeSwitch && setOwRtSwitch("rt");
                }}
                data-select={allFlightType && allFlightType !== "rt"}
              >
                RT
              </span>
              <span
                data-active={owRtSwitch === "ow"}
                onClick={() => {
                  flightTypeSwitch && setOwRtSwitch("ow");
                }}
                data-select={allFlightType && allFlightType !== "ow"}
              >
                OW
              </span>
            </div>
            <span>Flight Number :</span>
            <div className={styles.dropdown_wrapper}>
              <Dropdown
                data={flightNumberOptions}
                added={selectedFlightNumbers}
                setAdded={(newValue) => {
                  setSelectedFlightNumbers(newValue);
                }}
                setSearchFilter={(str: string) => {
                  setFlightNumberLookup(str);
                }}
                width={150}
              />
            </div>
            <div className={styles.header_button_wrapper} data-theme={theme}>
              <span
                className={styles.button}
                // onClick={() => setActiveStep(0)}
                data-active={activeStep === 0}
                data-theme={theme}
              >
                First
              </span>
              <span
                className={styles.button}
                onClick={() => setActiveStep(85)}
                data-active={activeStep === 85}
                data-theme={theme}
              >
                Bus
              </span>
              <span
                className={styles.button}
                onClick={() => setActiveStep(169)}
                data-active={activeStep === 169}
                data-theme={theme}
              >
                Eco
              </span>
              <span
                className={styles.active_button}
                style={{ left: activeStep }}
              ></span>
            </div>
          </div>
        </div>
        <div>
          <BasicFilter />
        </div>
        <div className={styles.carriers_wrapper}>
          <Carriers
            data={GetCarriers()}
            added={selectedCarriers}
            setAdded={setSelectedCarriers}
            carriersJson={carriersJson}
          />
        </div>
        <Card variant="primary" width={"100%"} height={471}>
          <Plotly data={graphData} layout={layout} height={455} />
        </Card>
        {dataHost.data && (
          <div className={styles.statistics_wrapper}>
            <StatisticsComponentHost data={[dataHost.data]} />
          </div>
        )}
        <div className={styles.statistics_wrapper}>
          <StatisticsComponent
            data={stats}
            hostCarrier={dataHost?.data?.carrier}
          />
        </div>
      </Card>
    </>
  );
}
