import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import { useContext, useEffect, useState } from "react";
import MenuComponent from "components/common/Menu";
import { CircularProgress } from "@mui/material";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";
import Table from "components/common/Table/newTable";
import { RecommendationsClient } from "lib/api/lfa/recommendations";
import { FilterContext } from "context-api/FilterContext";
import { useHistory } from "react-router-dom";
import getColumns from "./columns";
import moment from "moment";
import axios from "axios";
import { Tooltip } from "components/common/Tooltip";
import { useFullScreenListener } from "hooks/useFullScreenListener";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import Skeleton from "components/common/Skeleton";

interface IProps {
  filterIsOpen: boolean;
}

const RecommendationsTable = ({ filterIsOpen }: IProps) => {
  const [activeStep, setActiveStep] = useState(169);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [data, setData] = useState<any>({ data: [], columns: [] });
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState<
    { sortKey: string; sortOrder: "asc" | "desc" }[]
  >([]);
  const [meta, setMeta] = useState<{
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  }>({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1,
  });

  const { theme } = useContext(ThemeContext);
  const { filterList, setFilterList } = useContext(FilterContext);

  let history = useHistory();

  const getAuthToken = () => {
    let token: any = null;
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].split("=");
      if (cookie[0].trim().toLocaleLowerCase() === "token") {
        token = cookie[1];
      }
    }
    return token;
  };
  const handleExport = async (isExcelExport) => {
    setIsLoading(true);
    const url = isExcelExport
      ? `/api/lfa/rules-results/report?originCode=${filterList.origCityAirport}&destCode=${filterList.destCityAirport}`
      : `/api/msdv2/reports/inventory-changes?orig_city_airport=${filterList.origCityAirport}&dest_city_airport=${filterList.destCityAirport}`;
    try {
      const response = await axios({
        url,
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
        method: "GET",
        responseType: "blob", // important
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "data.csv";
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const params = {
      orig_city_airport: filterList.origCityAirport,
      dest_city_airport: filterList.destCityAirport,
      cabin: activeStep === 169 ? "ECO" : activeStep === 85 ? "BUS" : "FIRST",
      page_: paginationModel.page,
      limit_: paginationModel.pageSize,
      ...(sortModel &&
        sortModel?.length > 0 && { sort_: JSON.stringify(sortModel) }), // _sort varsa ekle
    };
    if (
      !filterIsOpen &&
      params.orig_city_airport.length > 0 &&
      params.dest_city_airport.length > 0 &&
      !redirectLoading
    ) {
      setSkeletonLoader(true);
      RecommendationsClient.fetchRecommendations({ params })
        .then((res: any) => {
          setData({
            data: res?.data ?? [],
            columns: getColumns(res?.labels ?? {}),
          });
          setMeta(res?.meta ?? {});
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [
    filterList,
    paginationModel,
    sortModel,
    activeStep,
    redirectLoading,
    filterIsOpen,
  ]);

  const onRowClick = (row) => {
    setRedirectLoading(true);
    let empty = filterList;
    Object.keys(empty).forEach((key) => {
      empty[key] = [];
    });
    setFilterList((prevState) => ({ ...prevState, ...empty }));
    setTimeout(() => {
      setFilterList((prevState) => ({
        ...prevState,
        origCityAirport: row?.hostFlightOD
          ? [row?.hostFlightOD?.slice(0, 3)]
          : [],
        destCityAirport: row?.hostFlightOD
          ? [row?.hostFlightOD?.slice(-3)]
          : [],
        mainCompetitor: row?.hoverText?.competitor
          ? [row?.hoverText?.competitor]
          : [],
        selectedCompetitors: ["All"],
        pos: ["All"],
        salesChannel: ["All"],
        cabin: ["All"],
        paxType: ["All"],
      }));
    }, 2000);
    setTimeout(() => {
      history.push(
        `/lowest-fare-calendar?view=day&date=${moment(
          `${row.hostFlightDepartureDate}`
        ).format("YYYY-MM-DD")}`
      );
    }, 3000);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  useFullScreenListener(() =>
    setIsFullScreen(document.fullscreenElement !== null)
  );
  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      const selectedElement = document.querySelector(`.custom-id-table`);
      selectedElement?.requestFullscreen();
    }
  }

  return redirectLoading ? (
    <CircularProgress className={styles.progres} />
  ) : (
    <div className={styles.container}>
      <Card variant="secondary">
        <div className={styles.header}>
          <div>
            <MenuComponent
              options={[
                {
                  title: "Export Excel",
                  handleClick: () => handleExport(true),
                },
                {
                  title: "Log Report",
                  handleClick: () => handleExport(false),
                },
              ]}
            >
              <div className={styles.buttons_wrapper}>
                <div className={styles.export_button}>
                  {isLoading ? (
                    <CircularProgress
                      style={{ color: "white", width: 24, height: 24 }}
                    />
                  ) : (
                    <img src={images.excel} alt="" />
                  )}
                </div>
              </div>
            </MenuComponent>
          </div>

          <div className={styles.header_button_wrapper} data-theme={theme}>
            <span
              className={styles.button}
              //   onClick={() => setActiveStep(0)}
              data-active={activeStep === 0}
              data-theme={theme}
            >
              First
            </span>
            <span
              className={styles.button}
              onClick={() => setActiveStep(85)}
              data-active={activeStep === 85}
              data-theme={theme}
            >
              Bus
            </span>
            <span
              className={styles.button}
              onClick={() => setActiveStep(169)}
              data-active={activeStep === 169}
              data-theme={theme}
            >
              Eco
            </span>
            <span
              className={styles.active_button}
              style={{ left: activeStep }}
            ></span>
          </div>
        </div>
        <div
          className={`${styles.card_body} custom-id-table`}
          style={{
            backgroundImage: isFullScreen ? "var(--bg-image)" : "none",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.fullscreen_button_wrapper}>
            <span
              onClick={() => {
                setIsFullScreen(!isFullScreen);
                toggleFullScreen();
              }}
              className={styles.fullscreen_button}
            >
              <Tooltip title="Full Screen">
                {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
              </Tooltip>
            </span>
          </div>
          {skeletonLoader ? (
            <Skeleton width={"100%"} height={500} />
          ) : (
            <Card variant="primary">
              <Table
                columns={data.columns}
                data={data.data}
                pagination
                paginationModel={meta}
                serverMode="server"
                defaultPageSize={10}
                nthChild
                onRowClick={onRowClick}
                onColumnOrderChange={(newColumnOrder) => {
                  console.log(
                    "Yeni Kolon Sıralaması:",
                    newColumnOrder.map((col) => col.key)
                  );
                }}
                onPaginationModelChange={(newPaginationModel) => {
                  // fetch data from server
                  setPaginationModel(newPaginationModel);
                }}
                onSortModelChange={(newSortModel) => {
                  // fetch data from server
                  setSortModel(newSortModel);
                }}
                onFilterModelChange={(newFilterModel) => {
                  // fetch data from server
                }}
              />
            </Card>
          )}
        </div>
      </Card>
    </div>
  );
};

export default RecommendationsTable;
